import React from "react";
import Layout from "../components/Layout";

// markup
const HomePage = () => {

  return (
    <Layout pageTitle="Home" pageSlug="index">
      <p>Planning, Coding, Analyzing.</p>
    </Layout>
  );
};

export default HomePage;

